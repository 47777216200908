import Navbar from "./components/navbar/Navbar";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

import 'bootstrap/dist/css/bootstrap.min.css';
import Information from "./components/information/Information";

function App() {
  return (
      <>
          <Navbar/>
          <Header/>
          <Information/>
          <Footer/>
      </>
  );
}

export default App;
