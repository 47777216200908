import styles from './Navbar.module.css'
import logoImage from '../../assets/logo.png'
import {Container} from "react-bootstrap";
const Navbar = () => {
    return(
        <div className={styles.navbar}>
             <Container>
                 <div className={styles.mobileNavbar}>
                    <div className={styles.navbarLogo}>
                        <img src={logoImage} alt="shk partner logo" />
                    </div>
                     <div className={styles.navbarIcons}>
                         <i className="fa-solid fa-phone"></i>
                         <i className="fa-solid fa-envelope"></i>
                     </div>
                 </div>
                 <div className={styles.desktopNavbar}>
                     <div className={styles.navbarLogo}>
                         <img src={logoImage} alt="shk partner logo" />
                     </div>
                     <div className={styles.navbarInfo}>
                         <p>+48 22 591-62-99 </p>
                         <p>warsaw@shkpartner.com</p>
                     </div>
                 </div>
             </Container>
        </div>
    )
}


export default Navbar;