import styles from './Information.module.css'
import {Container} from "react-bootstrap";

import logoSpImage from '../../assets/logoSP.png'

const Information = () => {
    return(
        <div className={styles.information}>
            <Container>
                <div className={styles.informationText}>
                    <p>Spory gospodarcze pozostające w niektórych branżach są nieodzowną częścią ich codziennej działalności, potrafią być kosztowne i czasochłonne, a ich wynik jest często niepewny. Finansowanie sporów przez podmioty trzecie (ang. „third-party funding”, „litigation finance” lub „TPF”) to natomiast zyskujące na popularności narzędzie procesowe, które może być wykorzystane do wsparcia podmiotów gospodarczych w zarządzaniu kosztami i ryzykiem związanym z dochodzeniem roszczeń w postępowaniach arbitrażowych i sądowych.</p>
                    <p>Tego typu finansowanie sporów powstało, aby wspierać klientów, którzy nie dysponują wystarczającymi środkami finansowymi  na prowadzenie postępowań sądowych oraz klientów, którzy posiadając takie środki, nie chca brać na siebie całego ryzyka finansowego związanego ze sporem. Prowadzenie sporów jest przedsięwzięciem wymagającym istotnych nakładów finansowych, które często obarczone jest znacznym ryzykiem. Rozumiejąc potrzeby naszych klientów oferujemy rozwiązanie, które zmniejsza, lub nawet całkowicie eliminuje, negatywne skutki finansowe związane z prowadzeniem sporów.</p>
                </div>

            </Container>
        </div>
    )
}

export default Information;