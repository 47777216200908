import styles from './Header.module.css'
import headerBackgroundImage from '../../assets/headerBackground.jpg'
import {Container} from "react-bootstrap";
const Header = () => {
    return(
        <div className={styles.header}>
            <div className={styles.headerShadow}></div>

            <Container>
                <div className={styles.headerText}>
                    <div className={styles.headerTitle}>
                        <h1>Specialists in litigation financing</h1>
                    </div>
                </div>
            </Container>
        </div>
    )
}


export default Header;