import styles from './Footer.module.css'
import {Col, Container, Row} from "react-bootstrap";
import logoImage from "../../assets/logo.png";
const Footer = () => {
    return(
        <div className={styles.footer}>
            <Container>
                <Row>

                    <Col xs={12} md={6} xl={3}>
                        <div className={styles.footerCardFirst}>
                            <img src={logoImage} alt="shk partner logo" />
                        </div>
                    </Col>

                    <Col xs={12} md={6} xl={3}>
                        <div className={styles.footerCard}>
                            <p>SKH Partner prosta spółka akcyjna</p>
                            <p>plac Bankowy 2</p>
                            <p>00-095 Warszawa</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <div className={styles.footerCard}>
                            <p>KRS 0001050817</p>
                            <p>NIP 5252966710</p>
                            <p>REGON 526011918</p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <div className={styles.footerCard}>
                            <p>+48 22 591-62-99</p>
                            <p>warsaw@shkpartner.com</p>
                        </div>
                    </Col>
                </Row>

                <div className={styles.footerText}>
                    <p>SHK Partner prosta spółka akcyjna  z siedzibą w Warszawie, pod adresem: ul. Plac Bankowy 2, 00-095 Warszawa, Polska, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego, prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0001050817, posiadająca numer NIP: 5252966710, numer REGON: 526011918</p>
                    <p>SHK FUND I Spółka z ograniczoną odpowiedzialnością  siedzibą w Warszawie, pod adresem: ul. Józefa Piusa Dziekońskiego 1, 00-728 Warszawa, Polska, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego, prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0001066036, posiadająca numer NIP: 5214042582, numer REGON: 526794872, o kapitale zakładowym 27 500,00 zł</p>
                </div>

                <div className={styles.footerCopyRight}>
                    <i className="fa-solid fa-copyright"></i>
                    <p>2023 Wszelkie prawa zastrzeżone</p>
                </div>
            </Container>
        </div>
    )
}


export default Footer;